import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery, Link } from 'gatsby'
import get from 'lodash/get'
import Header  from './header'
import base from './layout.scss'
import { isWindows } from '../../helpers'
import {init as initializeLoyaltyLion} from '../loyaltylion/functions'
import axios from 'axios'
import loadable from '@loadable/component'
const BackToTop = loadable(() => (import('../back-to-top/back-to-top')));
const Breadcrumbs = loadable(() => (import('../breadcrumbs/breadcrumbs')));
const Footer = loadable(() => (import('./footer')));
// import TopBannerCovid from '../notifications-banner/top-banner-covid'

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      reactLoaded: false,
      cartItems: []
    }
    this.cart = null
  }

  componentDidMount() {
    this.setState({
      reactLoaded: true
    })
    this.loadGoogleWebFonts()
    initializeLoyaltyLion(this.props.data.site.siteMetadata.loyaltylionKey)
  }
  
  /**
   * Loads webfonts
   * @return {void}
   */
  loadGoogleWebFonts() {
    const scriptId = 'google-fonts-script'
    window.WebFontConfig = {
  		google: {
  			families: ['Nunito+Sans:400,600,700']
  		}
  	}
    if (isWindows() && !document.getElementById(scriptId)) {
      const wf = document.createElement('script'), s = document.scripts[0]
  		wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'
      wf.id = scriptId
  		wf.async = true
  		s.parentNode.insertBefore(wf, s)
    }    
  }
  
  render() {
    const siteMeta = get(this, 'props.data.site.siteMetadata')
    const topHeader = this.props.topHeader || siteMeta.topHeader
    const { location, children } = this.props
    let headerOptions = {
      location: location
    }
    if (this.props.headerOptions) {
      headerOptions = {
        ...this.props.headerOptions
      }
    }
    
    if (!headerOptions.hidetopheader && topHeader) {
      headerOptions.topheader = topHeader
    }

    if ('menu' in this.props) {
      headerOptions.menu = this.props.menu
    }

    if (this.props.hideSearch) {
      headerOptions.hidesearch = this.props.hideSearch
    }

    if (this.props.hideCart) {
      headerOptions.hidecart = this.props.hideCart
    }

    if (this.props.headercta) {
      headerOptions.headercta = this.props.headercta
    }
    
    return (
      <>
        <Helmet>
          <body className={ `${!this.state.reactLoaded ? 'react-cloak' : ''} ${this.props.bodyClassName || ''}` } />
        </Helmet>
        {'hideHeader' in this.props === false && (this.props.header ? this.props.header : <Header { ...headerOptions} />) }
        <div className={`${this.props.containerClassName || ''} main-container`}>
          { this.props.breadcrumbs && <Breadcrumbs items={ this.props.breadcrumbs } location={ location } /> }
          { !this.props.hideBackToTop && <BackToTop /> }
          {/* <TopBannerCovid showCloseButton={ true } /> */}
          {children}
        </div>
        { 'hideFooter' in this.props === false && (this.props.footer ? this.props.footer : <Footer { ...this.props.footerOptions } />) }
      </>
    )
  }
}

export default (props) => {  
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title,
          description,
          topHeader,
          publisher {
            name
          },
          loyaltylionKey
        }
      }
    }
  `)
  return (
    <Template  {...props} data={data}/>
  )
};
