import React from "react";
import styles from './promo-bar.scss'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import defaultRTRenderOptions from "../../../contentful/rich-text-renderer-options"

class PromoBar extends React.Component {
  constructor(props) {
    super(props)

    // Condition of date and time for promo
    this.currentDate = new Date
    this.startPromoDate = new Date(this.props.startDate)
    this.endPromoDate = new Date(this.props.endDate)
  }

  render() {
    const options = {
      ...defaultRTRenderOptions
    }
    return (
      <>
        { this.currentDate.getTime() >= this.startPromoDate.getTime() && this.currentDate.getTime() <= this.endPromoDate.getTime() ? (
          <div className="py-1 text-center promo-bar-container" style={{backgroundColor: `${this.props.backgroundColor}`, color: `${this.props.textColor}`}} >{ documentToReactComponents(this.props.content.json, options) }</div>
        ) : '' }
      </>
    )
  }
}

export default PromoBar
