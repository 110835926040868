import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { isExternalUrlOrHash, slashLink, extractValueFromAttributes, collectionUrl, blogUrl, loggedInUser } from '../../../src/helpers.js'
import UserIcon from '../../../static/img/icons/user-icon.svg';
import ChevronDown from '../../../static/img/icons/chevron-down.svg';
import { MdMenu, MdClose } from 'react-icons/md'
import styles from './main-navigation.scss'
import '../search/search.scss'
import 'instantsearch.css/themes/algolia.css';

import loadable from '@loadable/component'

const Cart = loadable(() => (import('../cart/cart')));
const Search = loadable(() => (import('../search/search')));

class MainNavigation extends React.Component {
  
  constructor(props) {
    super(props)

    this.handleChildToggle = this.handleChildToggle.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.state= {
      active: false,
      menuActive: true,
      loggedInUser: false
    };
  }
  
  componentDidMount() {
    this.setState({
      loggedInUser: loggedInUser()
    });
  }

  handleMenuClick(e) {
    this.setState( { menuActive: !this.state.menuActive } );
  }

  handleChildToggle(e) {
    this.setState( { active: !this.state.active } );

  }
  /**
   * Generates markup for mobile nav menu
   * @param  {ContentfulColumn} column
   * @return {HTMLDomElement}
   */
  mobileMenuRenderer(column) {
    return (         
      <div id={column.name} className="tabs" key={column.name}>
        { column.menuItems && column.menuItems.map((item, index) => {     
          let menuClass = extractValueFromAttributes(item, `className`, ``)      
          return (
            <ul className={menuClass} key={index}>
              { menuClass === 'menu-with-heading' && <li>{item.label}</li>}
              { item.menuItems && item.menuItems.map((innerItem, index) => {
                let subMenuClass = extractValueFromAttributes(innerItem, `className`, ``) 
                let smallText = extractValueFromAttributes(innerItem, `smallText`, ``)
                return (
                  <li key={index} className={subMenuClass}>
                    { innerItem.url && (
                      isExternalUrlOrHash(innerItem.url) ? (
                        <a href={ innerItem.url } rel="noreferrer"  target="_blank">{ innerItem.label }</a>
                      ) : (
                        innerItem.name.match( 'Promo Link' ) ? (
                          <Link to={ slashLink(innerItem.url) }>
                            <span className="smaller-nav-text">{smallText}</span>
                            { innerItem.label }
                          </Link>
                        ) : (
                          <Link to={ slashLink(innerItem.url) }>
                            { innerItem.label }
                          </Link>
                        )
                      )
                    ) }
                    { subMenuClass === 'parent' && (
                      <span className={this.state.active ? 'child-toggle show-child': 'child-toggle'} onClick={this.handleChildToggle}>
                        <img src={ChevronDown} alt={this.state.active ? 'Close Menu Item' : 'Open Menu Item' }/>
                      </span>
                    ) }
                    <ul className="child">
                      {innerItem.menuItems && innerItem.menuItems.map((subInnerItem, i) => (
                        <li key={i}>
                          <Link to={ slashLink(subInnerItem.url) }>
                            { subInnerItem.label }
                          </Link>                                      
                        </li> )
                      )}
                    </ul>
                  </li> 
                ) } 
              ) }
            </ul>
          ) } 
        ) }
      </div>
    )
  }
  /**
   * Generates markup desktop mega menus
   * @param {mobileMenuVars[]} menu
   * @param  {ContentfulColumn} column 
   * @return {HTMLDomElement}
   */
  desktopMenuRenderer(menu, column) {
    return (
      column.name.match(menu) && (
        <div key={menu}>
          <span className="text-primary font-weight-bold">{menu}</span>
          <div className="mega-menu">
            <div className="wrapper">
              { column.menuItems && column.menuItems.map((item, index) => {     
                let menuClass = extractValueFromAttributes(item, `className`, ``)      
                return (
                  <ul className={menuClass} key={index}>
                    { menuClass === 'menu-with-heading' && (
                      <li>{item.label}</li>
                    ) }
                    { item.menuItems && item.menuItems.map((innerItem, index) => {
                      let subMenuClass = extractValueFromAttributes(innerItem, `className`, ``) 
                      let smallText = extractValueFromAttributes(innerItem, `smallText`, ``)
                      return (
                        <li key={index} className={subMenuClass}>
                          { innerItem.url && (            
                            isExternalUrlOrHash(innerItem.url) ? (
                              <a href={ innerItem.url } rel="noreferrer"  target="_blank">{ innerItem.label }</a>
                            ) : (
                              innerItem.name.match( 'Promo Link' )? (
                                <Link to={ slashLink(innerItem.url) }>
                                  <span className="smaller-nav-text">{smallText}</span>
                                  { innerItem.label }
                                </Link>
                              ) : (
                                <Link to={ slashLink(innerItem.url) }>
                                  { innerItem.label }
                                </Link>
                              )
                            )
                          ) }
                          { subMenuClass === 'parent' && (
                            <span className={this.state.active ? 'child-toggle show-child': 'child-toggle'} onClick={this.handleChildToggle}>
                              <img src={ChevronDown} alt={this.state.active ? 'Close Menu Item' : 'Open Menu Item' }/>
                            </span>
                          )}
                          <ul className="child">
                            {innerItem.menuItems && innerItem.menuItems.map((subInnerItem, i) => {  
                              return (
                                <li key={i}>
                                  <Link to={ slashLink(subInnerItem.url) }>
                                    { subInnerItem.label }
                                  </Link>                                      
                                </li>
                              )  
                            })}
                          </ul>
                        </li>
                      )
                    }
                  ) }
                </ul> )
              }) }
            </div>
          </div>
        </div>
      )
    )
  }
  
  /**
   * Renders menu from contentful
   * @param  {Object} menu
   * @return {HTMLDomElement}
   */
  renderMenuFromContentful(menu) {  
    const { logo, location } = this.props
    const menuId = extractValueFromAttributes(menu, `id`, `main-menu-honestpaws`)

      return (
        <Navbar expand="lg" id={ menuId } className="row main-navigation-menu new-nav-menu" title="Main Menu Navigation">
        <Navbar.Toggle className="text-left" title="Click to Toggle Menu">
          <MdMenu className="ico-open" title="Open Icon"/>
          <MdClose className="ico-close"  title="Close Icon" />
        </Navbar.Toggle>
        {/* When it's false, it won't render at all (this way we prevent this being rendered on the server side)  */}
        { this.state.loggedInUser && (
          <a href="https://portal.honestpaws.com/account" rel="noreferrer"  className="font-weight-bold text-primary login-link desktop">
            <img src={UserIcon} alt="Go to my Account's Portal"/>
            <span>My Account</span>
          </a>
        ) } 
        { this.state.loggedInUser === null &&
           (
          <a href="https://portal.honestpaws.com/account/login" rel="noreferrer"  className="font-weight-bold text-primary login-link desktop">
            <img src={UserIcon} alt="Log into Account's Portal"/>
            <span>Login</span>
          </a>
        ) }
        

        <div className="nav-left" tabIndex="0" role="button"  title={`Move the mouse over to open menu.`}>
          {  menu.menuColumns &&  menu.menuColumns.map((column, index) => {
              let menu = column.name;
              return index === 0 && this.desktopMenuRenderer(menu, column)
          }) }
        </div>

        <Link to="/" className="text-center text-lg-left navbar-brand">
          { logo instanceof Object === true && 'childImageSharp' in logo ? (
            <Img fluid={logo.childImageSharp.fluid} alt="Honest Paws Logo" className="img-fluid logo mx-auto mx-lg-0" />
          ) : (
            <img src={logo} alt="Honest Paws Logo" className="img-fluid logo mx-auto mx-lg-0" />
          )}
        </Link>
        
        <Navbar.Collapse id="navbarNav" className="col-12 col-md-8 p-0 text-center">
          <div className="clearfix"></div>
          <Nav as="ul" className="justify-content-center w-100" title="Main Navigation">
            <div className={this.state.menuActive === true ? 'shop mobile-menu': 'learn mobile-menu'} onClick={this.handleMenuClick}>
              <div className={this.state.menuActive === true ? 'active mobile-toggle': 'mobile-toggle'} onClick={this.props.handleMenuClick} title="Click to Toggle 'Shop'">{ menu.menuColumns[0].name }</div>
              <div className={this.state.menuActive === false ? 'active mobile-toggle': 'mobile-toggle'}  onClick={this.props.handleMenuClick} title="Click to Toggle 'Learn'">{ menu.menuColumns[1].name }</div>
            </div>
            {  menu.menuColumns &&  menu.menuColumns.map((column, index) => {
                return this.mobileMenuRenderer(column);
              }) }
            
          </Nav>
        </Navbar.Collapse>
  
        <div className="nav-right" tabIndex="0" role="button" title={`Move the mouse over to open menu.`}>
          { menu.menuColumns &&  menu.menuColumns.map((column, index) => {
            let menu = column.name;
            return index === 1 && this.desktopMenuRenderer(menu, column);
          })}
        </div>
        { menu.highlightedMenuItem && (
          <div className="col-lg-2 d-none d-lg-flex highlighted-item">
            {
              isExternalUrlOrHash(menu.highlightedMenuItem.url) ? (
                <a href={ menu.highlightedMenuItem.url } target='_blank' rel="noreferrer"  className='btn btn-white-secondary'>{ menu.highlightedMenuItem.label }</a>
              ) : (
                <Link className='btn btn-white-secondary' to={ slashLink(menu.highlightedMenuItem.url) }>{ menu.highlightedMenuItem.label }</Link>
              )
            }
          </div>       
        ) } 
        {/* When it's false, it won't render at all (this way we prevent this being rendered on the server side)  */}
        { this.state.loggedInUser && (
          <a href="https://portal.honestpaws.com/account" title="Go to Account's Portal" rel="noreferrer"  className="font-weight-bold text-primary login-link mobile">
            <img src={UserIcon} alt="Go to my Account's Portal"/>
          </a>
        ) } 
        {
         this.state.loggedInUser === null && (
          <a href="https://portal.honestpaws.com/account/login" title="Log into Account's Portal" rel="noreferrer"  className="font-weight-bold text-primary login-link mobile">
            <img src={UserIcon} alt="Log into Account's Portal"/>
          </a>
        ) }
        
        { !this.props.hideSearch && <Search location={location} /> }
        { !this.props.hideCart && <Cart location={location}/> }
        { this.props.headerCTA && <div className={`col-md-2 p-0 ${this.props.headerCTA.className} `}><Link to={this.props.headerCTA.url} className="btn btn-success px-1 py-3">{this.props.headerCTA.label}</Link></div> }

      </Navbar>
      )
    
    
  }
  
  /**
   * Renders logo menu
   * @return {HTMLDomElement}
   */
  renderLogoMenu() {
    const { logo } = this.props
    return (      
      <Navbar expand="lg" id='logo-menu' className="row main-navigation-menu text-center">
        { this.props.hideLinkOnLogo ? (
          <div className="col-12 col-lg-2 offset-lg-5 pt-0 pl-0 pr-0 text-center text-lg-left navbar-brand-without-menu compliant-menu">
            { logo instanceof Object === true && 'childImageSharp' in logo ? (
              <Img fluid={logo.childImageSharp.fluid} alt="Honest Paws Logo" className="img-fluid logo mx-auto mx-lg-0" />
            ) : (
              <img src={logo} alt="Honest Paws Logo" className="img-fluid logo mx-auto mx-lg-0" />
            )}
          </div>
        ) : (
          <Link to="/" className="col-12 col-lg-2 offset-lg-5 pt-0 pl-0 pr-0 text-center text-lg-left navbar-brand-without-menu" title="Go to Homepage">
            { logo instanceof Object === true && 'childImageSharp' in logo ? (
              <Img fluid={logo.childImageSharp.fluid} alt="Honest Paws Homepage" className="img-fluid logo mx-auto mx-lg-0" />
            ) : (
              <img src={logo} alt="Honest Paws Homepage" className="img-fluid logo mx-auto mx-lg-0" />
            )}
          </Link>
        )}
      </Navbar>
    )
  }
  
  render() {    
    return this.props.menu ? this.renderMenuFromContentful(this.props.menu) : this.renderLogoMenu()
  }
}

export default (props) => { 
  return (
    <MainNavigation  {...props} />
  )
};
