import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { globalHistory as history } from '@reach/router'
import {omit, get} from 'lodash'
import Helmet from 'react-helmet'

class SEO extends React.Component {
  constructor(props) {
    super(props)
    const { site :  { siteMetadata : { title, description, author, siteUrl, siteUrlEs } } } = this.props.data
    const metaTitle = get(this.props, 'meta.title', title)
    const metaDescription = get(this.props, 'meta.description', get(this.props, 'description', description))
    const defaultMetaImage = get(this.props, 'data.ogDefaultImage.childImageSharp.fluid')
    const metaImage = get(this.props, 'meta.image', defaultMetaImage) || defaultMetaImage
    const metaKeywords = get(this.props, 'meta.keywords')
    const ogMeta = get(this.props, 'meta.ogMeta', {}) || {}
    const { location } = history
    this.seo = {
      htmlAttributes: {lang: 'en'},
      title: get(this.props, 'title', title),
      meta: [
        {
          name: 'Content-Type',
          content: 'text/html; charset=UTF-8',
        },
        {
          name: 'title',
          content: metaTitle
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: metaTitle
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:url',
          content: location.href
        },
        // Property below can be overridden by different templates
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:title',
          content: metaTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:creator',
          content: get(this.props, 'meta.author', author.name)
        }
      ]
    }
    
    if (metaKeywords) {
      this.seo.meta.push(
        {
          name: 'keywords',
          content: metaKeywords.join(',')    
        }
      )
    }
    
    if (metaImage && metaImage.src) {
      let miSrc = metaImage.src
      if (miSrc.match(/^\/[^\/]+/)) { //Meaning it's a relative path to our files
        miSrc = `${siteUrl}${miSrc}`
      } else if (miSrc.match(/^\/\/.+/)) {
        miSrc = `https:${miSrc}`
      }
      let metaImageStructure = [
        {
          property: "og:image",
          content: miSrc
        }
      ]
      if (metaImage.width) {
        metaImageStructure.push({
          property: "og:image:width",
          content: metaImage.width
        })
      }
      if (metaImage.height) {
        metaImageStructure.push({
          property: "og:image:height",
          content: metaImage.height
        })
      }
      
      if (metaImage.height && metaImage.width && metaImage.height === metaImage.width) {
        this.seo.meta.push({
          name: "twitter:card",
          content: "summary"
        })
      } else {
        metaImageStructure.push({
          property: "twitter:image",
          content: miSrc
        })
        metaImageStructure.push({
          name: "twitter:card",
          content: "summary_large_image"
        })
      }
      this.seo.meta = this.seo.meta.concat(metaImageStructure)
    } else {
      this.seo.meta.push({
        name: "twitter:card",
        content: "summary"
      })
    }
    
    if (ogMeta) {
      for (let property in ogMeta) {
        this.seo.meta.push({
          property,
          content: ogMeta[property]
        })
      }
    }
    
    // this.hreflangUrls = [
    //   { lang: 'x-default', href: siteUrl },
    //   { lang: 'en', href: siteUrl },
    //   { lang: 'es', href: siteUrlEs }
    // ]
    
    // If a paremeter if found on the url, we won't want to index it regardless what the settings are
    this.disallowIndexing = true // get(this, 'props.data.site.siteMetadata.environment') !== 'production' || !!location.search || this.props.disallowIndexing
    this.disallowFollowing = !!location.search
  }
  render() {
    return (
      <Helmet { ...this.seo } >
        {/*{ this.hreflangUrls.map(({ lang, href }) => (<link rel="alternate" key={ lang } href={ href } hreflang={ lang } />)) }*/}
        { this.props.structuredData && typeof JSON !== undefined  ? (
          <script type='application/ld+json'>
            { JSON.stringify(this.props.structuredData) }
          </script>
        ) : '' }
        
        { this.disallowIndexing === true ? (
          <meta name="robots" content={`noindex, ${this.disallowFollowing ? 'nofollow' : 'follow'}`} />
        ) : '' }
        { this.props.paginationAttributes && this.props.paginationAttributes.currentLink && <link rel="canonical" href={this.props.paginationAttributes.currentLink} /> }
        { this.props.paginationAttributes && this.props.paginationAttributes.prevLink && <link rel="prev" href={this.props.paginationAttributes.prevLink} /> }
        { this.props.paginationAttributes && this.props.paginationAttributes.nextLink && <link rel="next" href={this.props.paginationAttributes.nextLink} /> }
        { this.props.children }
      </Helmet>
    )
  }
}
export default (props) => {  
  const data = useStaticQuery(graphql`
    query SeoQuery {
      site {
        siteMetadata {
          title,
          description,
          author {
            name
          }
          siteUrl
          siteUrlEs
          environment
        }
      }
      ogDefaultImage: file(relativePath: { eq: "og-default-3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <SEO  {...props} data={data}/>
  )
};
