import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { omit, get } from 'lodash'
import MainNavigation from './main-navigation'
import PromoBar from '../promo-bar/promo-bar'
import headerStyles from './header.scss'
import Helmet from 'react-helmet'

class Header extends React.Component {
  render() {
    const { data } = this.props
    const props = omit(this.props, ['data', 'className', 'topHeader'])
    const siteMeta = get(this, 'props.data.site.siteMetadata')
    const headerClassName = get(this.props, 'className', '')

    const promoBarAttrs = {
      // ++++++ Black Friday Promo Info +++++++++ //
      // className: 'py-1 bg-black text-white text-center promo-bar-container',
      // promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2 black-friday-yellow">Get <b>25% off</b> the Calm Vest. <br className="d-lg-none"/>Code - <a className="text-white" href="/products/calm-vest/?discount=KEEPCALM"><b><u>KEEPCALM</u></b></a> valid till OCT/14</p>]

      // ++++++ SANTAPAWS Promo Info +++++++++ //
      // className: 'py-1 bg-dark-red text-white text-center promo-bar-container',
      // promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2">Holiday Sale! <b>25% off</b> ALL OILS with <br className="d-lg-none"/>code: <b>SANTAPAWS</b>&nbsp;&nbsp;&nbsp;&nbsp;<a className="text-white" href="/collections/santapaws/?discount=SANTAPAWS"><u>Shop Now</u></a></p>]

      // ++++++ BYE2020 Promo Info +++++++++ //
      // className: 'py-1 bg-dark-red text-white text-center promo-bar-container',
      // promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2">End of Year Sale! <b>30% off</b> selected products with <br className="d-lg-none"/>code: <b>BYE2020</b>&nbsp;&nbsp;&nbsp;&nbsp;<a className="text-white" href="/collections/bye-2020/?discount=BYE2020"><u>Shop Now</u></a></p>]

      // ++++++ Valentine Promo Info +++++++++ //
      // className: 'py-1 bg-dark-red text-white text-center promo-bar-container',
      // promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2">Valentine’s Sale! <b>25% off</b> Wellness products with <br className="d-lg-none"/>Code: <b>PAWLOVE</b>&nbsp;&nbsp;&nbsp;&nbsp;<a className="text-white" href="/collections/pawlove/?discount=pawlove"><u>Shop Now</u></a></p>]

      // ++++++ CALMSUMMER Promo Info +++++++++ //
      // className: 'py-1 bg-calm-blue text-black text-center promo-bar-container',
      // promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2">Get 25% off all Calm products with <br className="d-lg-none"/>code <b>CALMSUMMER</b>&nbsp;&nbsp;<a className="text-black" href="/collections/calm-sale/?discount=CALMSUMMER"><u>Shop Now</u></a></p>]

      // ++++++ Christmas 2021 +++++++++ //
      // className: 'py-1 bg-dark-red text-white text-center promo-bar-container',
      // promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2"><b>Save 25%</b> on all products! Use code <b>PAWLIDAYS</b> on any one time purchase. <br className="d-lg-none"/><a className="text-secondary" href="/collections/?discount=PAWLIDAYS"><u><b>Shop Now</b></u></a></p>]

      // ++++++ Promo Info +++++++++ //
      className: 'py-1 bg-primary text-white text-center promo-bar-container',
      promoTitle : [<p key="promo-bar-container" className="mb-0 mx-2">Enjoy 25% off all one-time purchases <br className="d-lg-none"/>when you use code <a className="text-white" href="/collections/?discount=25SPRING"><u><b>25SPRING</b></u></a></p>]
    }


    return (
      <>
        <Helmet>          
          <link rel="preload" href="/img/honestpaws_logo_menu.png" as="image"/>,
        </Helmet>

        <PromoBar {...data.homepageConfig.bannerSiteWide} />

        <header
          {...props}
          className={`${headerClassName} bg-white ${
            props.hidesearch === true ? 'header-without-search' : ''
          }`}
          id="app-header"
          itemID={`#${siteMeta.publisher.name.replace(' ', '')}Org`}
          itemScope
          itemType="http://schema.org/Organization"
        >
          <div className="container pb-2 pt-2 new-nav-wrapper">
            <MainNavigation
              menu={'menu' in props === false ? data.mainMenu : props.menu}
              hideCart={props.hidecart}
              hideSearch={props.hidesearch}
              hideLinkOnLogo={props.hidelinkonlogo}
              // logo={data.headerLogo}
              logo='/img/honestpaws_logo_menu.png'
              showCart={props.showCart}
              headerCTA={props.headercta}
              location={props.location}
            />
          </div>
          <meta itemProp="name" content={siteMeta.publisher.name} />
          <meta itemProp="url" content={siteMeta.publisher.url} />
          <meta itemProp="additionalType" content={siteMeta.publisher.additionalTypel} />
          <meta itemProp="areaServed" content={siteMeta.publisher.areaServed} />
          <meta itemProp="alternateName" content={siteMeta.publisher.alternateName} />
          <meta itemProp="foundingDate" content={siteMeta.publisher.foundingDate} />
          <meta itemProp="mainEntityOfPage" content={siteMeta.publisher.mainEntityOfPage} />
          {siteMeta.publisher.sameAs.map((row, i) => {
            return (
              <meta itemProp="sameAs" content={row} key={i} />
            )
          })}
          <meta itemProp="description" content={siteMeta.description} />
          <meta itemProp="telephone" content={siteMeta.publisher.phone} />
          <meta itemProp="email" content={siteMeta.publisher.emails.contact} />
          <div
            itemProp="logo"
            itemScope
            itemType="https://schema.org/ImageObject"
          >
            <meta itemProp="url" content={siteMeta.publisher.logo} />
          </div>
          <div
            itemProp="address"
            itemScope
            itemType="https://schema.org/PostalAddress"
          >
            {/*<meta*/}
              {/*itemProp="addressRegion"*/}
              {/*content={siteMeta.publisher.address.state}*/}
            {/*/>*/}
            <meta
              itemProp="streetAddress"
              content={siteMeta.publisher.address.streetAddress}
            />
            <meta
              itemProp="addressLocality"
              content={siteMeta.publisher.address.addressLocality}
            />
            <meta
              itemProp="postalCode"
              content={siteMeta.publisher.address.zip}
            />
            <meta
              itemProp="addressCountry"
              content={siteMeta.publisher.address.country}
            />
          </div>
          <div itemProp="founder" itemScope itemType="https://schema.org/Person">
            <meta itemProp="name"
               content={siteMeta.publisher.person.name}
            />
            <meta itemProp="gender"
               content={siteMeta.publisher.person.gender}
            />
            <meta itemProp="jobTitle"
               content={siteMeta.publisher.person.jobTitle}
            />
            <meta itemProp="image"
               content={siteMeta.publisher.person.image}
            />
            {siteMeta.publisher.person.sameAs.map((row, i) => {
              return (
                <meta itemProp="sameAs" content={row} key={i} />
              )
            })}
          </div>
          <div itemProp="contactPoint" itemScope itemType="https://schema.org/contactPoint">
            <meta itemProp="contactType"
                  content={siteMeta.publisher.contactPoint.contactType}
            />
            <meta itemProp="email"
                  content={siteMeta.publisher.contactPoint.email}
            />
            <meta itemProp="url"
                  content={siteMeta.publisher.contactPoint.url}
            />
          </div>
        </header>
      </>
    )
        
  }
}
export default props => {
  const data = useStaticQuery(graphql`
    query NavigationQuery {
      site {
        siteMetadata {
          title
          description
          topHeader
          promoBarActivated
          publisher {
            additionalType
            areaServed
            alternateName
            foundingDate
            mainEntityOfPage
            sameAs
            contactPoint {
              contactType
              email
              url
            }
            person {
              name
              gender
              jobTitle
              image
              sameAs
            }
            address {
              streetAddress
              addressLocality
              city
              country
              line_1
              line_2
              state
              zip
            }
            emails {
              contact
            }
            logo
            name
            phone
            url
          }
        }
      }
      headerLogo: file(relativePath: { eq: "honestpaws_logo_menu.png" }) {
        childImageSharp {
          fluid(maxHeight: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mainMenu: contentfulMenu( name: {eq: "Main Menu New Nav"} ) {
        ...contentfulMenu
      }
      homepageConfig: contentfulHomePage(title: {eq: "Homepage EN"}) {
        bannerSiteWide {
          ...bannerSiteWide
        }
      }
    }
  `)
  return <Header {...props} data={data} />
}
