import React from 'react'
import { MARKS, BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Link } from 'gatsby'
import { get, kebabCase } from 'lodash'
import { isExternalUrlOrHash, isHash } from "../src/helpers.js"

import loadable from '@loadable/component'

const Accordion = loadable(() => (import('../src/components/contentful/accordion')))
const CallToAction = loadable(() => (import('../src/components/contentful/call-to-action')))
const ImagesGrid = loadable(() => (import('../src/components/contentful/images-grid')))
const AlignedImageBlock = loadable(() => (import('../src/components/contentful/aligned-image-block')))
const Ingredient = loadable(() => (import('../src/components/contentful/ingredient')))
const Table = loadable(() => (import('../src/components/contentful/table')))
const Image = loadable(() => (import('../src/components/contentful/image')))

/**
 * More options here: https://www.npmjs.com/package/@contentful/rich-text-react-renderer
 */

export default {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      let element = children
      const entryType = get(node, 'data.target.sys.contentType.sys.id')
      switch (entryType) {
        case `accordeon` : 
          element = <Accordion node={ node.data.target } />
          break
        case `alignedImage` : 
          element = <AlignedImageBlock node={node.data.target} />
          break
        case `callToAction` : 
          element = <CallToAction node={ node.data.target } />
          break
        case `imagesBlock` : 
          element = <ImagesGrid node={ node.data.target } />
          break
        case `ingredient` : 
          element = <Ingredient node={ node.data.target } />
          break
        case `table` : 
          element = <Table node={ node.data.target } />
          break
      }
      
      return (
        <>
          {element}
        </>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      let element = children
      const entryType = get(node, 'data.target.sys.type')
      switch (entryType) {
        case 'Asset' :
          element = <Image node={ node.data.target } />
          break
      }
      
      return (
        <>
          {element}
        </>
      )
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 id={`h-${kebabCase(node.content[0].value)}`}>{ children }</h1>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 id={`h-${kebabCase(node.content[0].value)}`}>{ children }</h3>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 id={`h-${kebabCase(node.content[0].value)}`}>{ children }</h2>
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return <h3 id={`h-${kebabCase(node.content[0].value)}`}>{ children }</h3>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      let contentToReturn = <p>{children}</p>
      for (let i in children) {
        const t = children[i] instanceof Array === true ? children[i][0] : children[i]
        if (typeof t === 'string' && t.match(/\[infographic .+\]/)) {
          const props = {};
          t.replace('[infographic', '').replace(']', '').trim().split(' ').forEach(p => {
            const [name, value] = p.split("=")
            props[name] = value.replace(/\"/gi, "")
          })
          if (get(props, 'type') === 'flourish') {
            const id = get(props, 'id');
            if (id) {
              // contentToReturn = <div className="flourish-embed flourish-bar-chart-race" data-src={`visualisation/${id}`}><script src="https://public.flourish.studio/resources/embed.js"></script></div>
              contentToReturn = (
                <div className="iframe-container">
                  <iframe src={`https://flo.uri.sh/visualisation/${id}/embed`} title='Interactive or visual content' frameBorder='0' scrolling='no' style={{width: '100%', height: '600px'}} sandbox='allow-same-origin allow-forms allow-scripts allow-downloads allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation'></iframe>
                  <div style={{width: '100% !important;', 'margin-top': '4px !important;', 'text-align': 'right !important;'}}>
                    <a className='flourish-credit' href='https://public.flourish.studio/visualisation/6106773/?utm_source=embed&utm_campaign=visualisation/6106773' target='_top' style={{'text-decoration': 'none !important;'}}>
                      <img alt='Made with Flourish' src='https://public.flourish.studio/resources/made_with_flourish.svg' style={{width: '105px!important;', height: '16px!important;', border: 'none!important;', margin: '0!important;'}} /> 
                    </a>
                  </div>
                </div>
              )
              break;
            }
          }
        } 
      }
      
      
      return contentToReturn
    },
    [INLINES.HYPERLINK]: ({data, content}) => {
      const { uri } = data
      const label = content.map((i) => i.value).join('')
      if (!label) return '';
      // if (isExternalUrlOrHash(uri)) {
      //   return (<a href={uri} rel="noreferrer" target={ isHash(uri) ? '_top' : '_blank' }>{label}</a>)
      // } else {
      //   return (<Link to={uri}>{label}</Link>)
      // }
      if (isExternalUrlOrHash(uri)) {
        return (<a href={uri} rel="noreferrer" target='_top'>{label}</a>)
      } else {
        return (<a href={`https://www.honestpaws.com/${uri}`} rel="noreferrer" target='_top'>{label}</a>)
      }
    }
  }
}